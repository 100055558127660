import React, { useEffect, useState } from 'react';
import { GlobalStyle, ThemeProvider } from '@react95/core';
import { createGlobalStyle } from 'styled-components';
import DataService from '../services/dataService';
import DataContext from '../contexts/dataContext';
import Taskbar from './Taskbar';
import Desktop from './Desktop';
import ContextMenu from './ContextMenu';

const dataService = new DataService();

const BodyFontSizeOverride = createGlobalStyle`
    body {
      font-size: 15px;
      background-image: url('/images/bg-t.jpg');
      background-size: auto 33%;
      height: 100vh;
    }
  `;

const App = () => {
  const [taskbarRenderKey, setTaskbarRenderKey] = useState(0);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [desktopVisible, setDesktopVisible] = useState(true);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setContextMenuPosition({ x: e.pageX, y: e.pageY });
    setContextMenuVisible(true);
  };

  const handleRefreshClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const video = document.querySelector('.player video');
    video && video.pause();
    setDesktopVisible(false);
    setTimeout(() => setDesktopVisible(true), 500);
  };

  const handleCloseContextMenu = () => {
    setContextMenuVisible(false);
  };

  useEffect(() => {
    // Добавить обработчик события click на document
    const handleDocumentClick = (e) => {
      if (contextMenuVisible) {
        handleCloseContextMenu();
        setTaskbarRenderKey((prevKey) => prevKey + 1);
      }
      const taskbarElement = document.querySelector('.taskbar__list');
      const taskbarMain = document.querySelector('.taskbar__main');
      if (
        taskbarElement &&
        !(taskbarElement && taskbarMain).contains(e.target)
      ) {
        setTaskbarRenderKey((prevKey) => prevKey + 1);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      // Удалить обработчик события при размонтировании компонента
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [contextMenuVisible]);

  return (
    <DataContext.Provider value={dataService}>
      <ThemeProvider>
        <div
          onContextMenu={handleContextMenu}
          style={{ height: '100vh', position: 'relative', top: 0, left: 0 }}
        >
          {contextMenuVisible && (
            <ContextMenu
              isVisible={contextMenuVisible}
              positionX={contextMenuPosition.x}
              positionY={contextMenuPosition.y}
              onRefreshClick={handleRefreshClick}
            />
          )}
          {desktopVisible ? (
            <div>
              <Desktop />
            </div>
          ) : (
            <div style={{ display: 'none' }}>
              <Desktop />
            </div>
          )}
          <GlobalStyle />
          <BodyFontSizeOverride />
          <div className='taskbar__main'>
            {/* {desktopVisible &&  */}
            <Taskbar key={taskbarRenderKey} />
            {/* } */}
          </div>
        </div>
      </ThemeProvider>
    </DataContext.Provider>
  );
};

export default App;
