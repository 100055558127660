import Webamp from 'webamp';

let webamp;
const startWebamp = () => {
  if (Webamp.browserIsSupported()) {
    webamp = new Webamp({
      initialTracks: [
        {
          metaData: {
            artist: '2 Pac feat. Dr.Dre',
            title: 'California Love',
          },
          url: `${process.env.PUBLIC_URL}/music/california-love.mp3`,
          duration: 242,
        },
        {
          metaData: {
            artist: 'Coolio',
            title: 'Gangstas Paradise',
          },
          url: `${process.env.PUBLIC_URL}/music/gangstas-paradise.mp3`,
          duration: 240,
        },
        {
          metaData: {
            artist: 'DMX',
            title: 'Where The Hood At',
          },
          url: `${process.env.PUBLIC_URL}/music/where-the-hood-at.mp3`,
          duration: 286,
        },
        {
          metaData: {
            artist: 'Eminem',
            title: 'Not Afraid',
          },
          url: `${process.env.PUBLIC_URL}/music/not-afraid.mp3`,
          duration: 248,
        },
        {
          metaData: {
            artist: 'Eminem feat. Nate Dogg',
            title: 'Till I Collapse',
          },
          url: `${process.env.PUBLIC_URL}/music/till-i-collapse.mp3`,
          duration: 297,
        },
      ],
    });

    webamp.onClose(() => {
      webamp.dispose();
    });

    webamp.renderWhenReady(document.getElementById('winamp-container'));
  }
};

const disposeWebamp = () => {
  if (webamp) webamp.dispose();
};

export { startWebamp, disposeWebamp };
