import React from 'react';
import { Modal, Frame } from '@react95/core';

const MyComp = ({ closeMyComp }) => {
  const systemInfo = {
    connection: window.navigator.hardwareConcurrency,
    os: window.navigator.platform,
    browser: window.navigator.userAgent,
    language: window.navigator.language,
    // Другие данные о системе...
  };
  return (
    <Modal
      icon='computer'
      title={`MyComp - ${systemInfo.os}`}
      closeModal={closeMyComp}
      buttons={[{ value: 'Close', onClick: closeMyComp }]}
      style={{
        width: 400,
      }}
      menu={[
        { name: 'File', list: [] },
        { name: 'Edit', list: [] },
      ]}
    >
      <Frame
        bg='white'
        boxShadow='in'
        height='100%'
        padding={20}
        style={{
          overflowY: 'auto',
          maxHeight: '60vh',
        }}
      >
        <h3>Information about your system</h3>
        <p>CPU cores: {systemInfo.connection}</p>
        <p>OS: {systemInfo.os}</p>
        <p>Browser: {systemInfo.browser}</p>
        <p>Language: {systemInfo.language}</p>
      </Frame>
    </Modal>
  );
};

export default MyComp;
