import React, { useState } from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  margin-bottom: 10px;
  margin-right: 15px;
  font-size: 1.1em;
`;

function Projects({ content, isMobile }) {
  const { projects } = content;

  // Add state to track video playback
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null);

  const toggleVideoPlayback = (idx) => {
    if (playingVideoIndex === idx) {
      setPlayingVideoIndex(null); // Pause the video
    } else {
      setPlayingVideoIndex(idx); // Play the clicked video
    }
  };

  return (
    <div>
      <h2>Projects</h2>
      {projects.map((project, idx) => (
        <div key={idx}>
          <h3>{project.title}</h3>
          <p>
            <strong>Description</strong>: {project.description}
          </p>
          <p>
            {project.video && (
              <video
                width={isMobile ? 280 : 380}
                controls={playingVideoIndex !== idx}
                onClick={() => toggleVideoPlayback(idx)}
              >
                <source
                  src={project.video}
                  type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                />
              </video>
            )}
          </p>
          <p>
            {project.image && (
              <img
                width={isMobile ? 280 : 380}
                alt={project.image.title}
                src={project.image}
              ></img>
            )}
          </p>
          {project.myRole && (
            <p>
              <strong>My role:</strong> {project.myRole}
            </p>
          )}
          <p>
            <strong>Tech Stack:</strong> <br />
            {project.techStack.map((item) => (
              <div>{item}</div>
            ))}
          </p>
          {project.url && (
            <StyledLink href={project.url} target='__blank'>
              Link
            </StyledLink>
          )}
          {project.repo && (
            <StyledLink href={project.repo} target='__blank'>
              GitHub Repo
            </StyledLink>
          )}
          <hr />
        </div>
      ))}
    </div>
  );
}

export default Projects;
