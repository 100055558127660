import React, { useContext } from 'react';
import { useState } from 'react';
import Draggable from 'react-draggable';
import Alert from '@react95/core/Alert';
import PlaylistMenu from './PlaylistMenu';
import { PlayerContextProvider } from '@cassette/core';
import { MediaPlayerUI } from 'win95-media-player-esm/lib/MediaPlayerUI';
import DataContext from '../contexts/dataContext';
import { Frame } from '@react95/core';

const VideoPlayer = ({ isMobile, closeVideo }) => {
  const data = useContext(DataContext);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState(1);
  const [activeTrackIndex, onSelectTrackIndex] = useState(0);

  const selectedPlaylist = data.getPlaylist(selectedPlaylistId);
  const playlist = selectedPlaylist ? selectedPlaylist.videos : [];
  const playlists = data.getPlaylists();

  const handlePlaylistSelect = (playlistId) => {
    setSelectedPlaylistId(playlistId);
    onSelectTrackIndex(0);
  };

  // =========== DRAGGING START =========== //
  const [activeDrags, setActiveDrags] = useState(0);
  const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  // =========== DRAGGING END=========== //

  // =========== ALERT START =========== //
  const [showAlert, toggleShowAlert] = useState(false);
  const handleOpenAlert = () => toggleShowAlert(true);
  const handleCloseAlert = () => toggleShowAlert(false);

  // =========== ALERT END =========== //

  return (
    <Draggable
      handle='.header'
      defaultPosition={{ x: isMobile ? 20 : 180, y: isMobile ? -200 : 0 }}
      onStart={onStart}
      onDrag={handleDrag}
      onStop={onStop}
    >
      <div
        style={{
          left: isMobile ? '5%' : '50%',
          top: isMobile ? '3%' : '10%',
          width: isMobile ? '90%' : 450,
        }}
      >
        <PlayerContextProvider playlist={playlist}>
          <MediaPlayerUI
            className='player'
            style={{ width: '100%' }}
            dragHandlerClassName='header'
            showVideo
            fullscreenEnabled
            minimizeClickHandler={handleOpenAlert}
            closeClickHandler={closeVideo}
            awaitingPlayResume={true}
          />
          <Frame className='playlist-selector' style={{ padding: '6px' }}>
            <Frame
              bg='white'
              boxShadow='in'
              height='100%'
              style={{
                overflowX: 'auto',
                maxHeight: '46px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  height: '46px',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  margin: 'auto 0',
                }}
              >
                {playlists.map((playlist) => (
                  <div
                    style={{
                      color:
                        playlist.id === selectedPlaylistId ? 'blue' : 'black',
                      fontSize: '15px',
                    }}
                    key={playlist.id}
                    onClick={() => handlePlaylistSelect(playlist.id)}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyItems: 'center',
                      }}
                    >
                      <img
                        style={{ cursor: 'pointer' }}
                        width={20}
                        src={playlist.icon}
                        alt={playlist.name}
                      />
                      <span style={{ cursor: 'pointer' }}>{playlist.name}</span>
                    </div>
                  </div>
                ))}
              </div>
            </Frame>
          </Frame>
          <PlaylistMenu
            style={{ width: isMobile ? '90%' : 450 }}
            activeTrackIndex={activeTrackIndex}
            onSelectTrackIndex={onSelectTrackIndex}
            isMobile={isMobile}
          />
        </PlayerContextProvider>
        {showAlert && (
          <Alert
            title='Critical error'
            type='error'
            message='Hasta la vista, bebe.'
            defaultPosition
            style={{ marginTop: '50px', marginLeft: '100px' }}
            closeAlert={handleCloseAlert}
            buttons={[
              {
                value: 'OK',
                onClick: handleCloseAlert,
              },
            ]}
          />
        )}
      </div>
    </Draggable>
  );
};

export default VideoPlayer;
