import React from 'react';
import styled from 'styled-components';
import { Icon } from '@react95/core';
import { startWebamp } from '../utils/startWebamp';

const StyledShorcut = styled.div`
  display: flex;
  align-items: start;
  margin-left: 20px;
  margin-top: 20px;

  .shortcut {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .shortcut__title {
    margin-top: 4px;
  }

  img {
    width: 32px; /* Укажите подходящую ширину */
    height: 32px; /* Укажите подходящую высоту */
  }

  span {
    background-color: white;
    padding: 2px;
  }
`;

function Shortcuts({ openExplorer, openVideo, openMyComp }) {
  return (
    <div>
      <StyledShorcut>
        <div className='shortcut'>
          <Icon
            className='pointer'
            name='computer'
            onClick={() => openMyComp()}
          />
          <div className='shortcut__title'>
            <span>My Comp</span>
          </div>
        </div>
      </StyledShorcut>
      <StyledShorcut>
        <div className='shortcut'>
          <Icon
            className='pointer'
            name='windows_explorer'
            onClick={() => openExplorer()}
          />
          <div className='shortcut__title'>
            <span>Explorer</span>
          </div>
        </div>
      </StyledShorcut>
      <StyledShorcut>
        <div className='shortcut'>
          <Icon
            className='pointer'
            name='mplayer_1_10'
            onClick={() => openVideo()}
          />
          <div className='shortcut__title'>
            <span>Mplayer</span>
          </div>
        </div>
      </StyledShorcut>
      <StyledShorcut>
        <div className='shortcut'>
          <img
            className='pointer'
            src='/images/winamp2-32x32.png'
            alt='Winamp'
            onClick={() => startWebamp()}
          />
          <div className='shortcut__title'>
            <span>Winamp</span>
          </div>
        </div>
      </StyledShorcut>
    </div>
  );
}

export default Shortcuts;
