import React from 'react';
import List from '@react95/core/List';

const ContextMenu = ({ isVisible, onRefreshClick, positionX, positionY }) => {
  const handleContextMenuClick = (e) => {
    // Предотвращаем закрытие меню, если клик был сделан на кнопке
    e.stopPropagation();
  };

  if (!isVisible) return null;

  return (
    <div>
      <List
        className='context-menu'
        style={{
          position: 'fixed',
          top: positionY + 'px',
          left: positionX + 'px',
          zIndex: 9999,
          color: '#868a8e',
        }}
      >
        <List.Item>View</List.Item>
        <List.Divider />
        <List.Item>Customize this Folder...</List.Item>
        <List.Divider />
        <List.Item>Arrange Icons</List.Item>
        <List.Item>Line Up Icons</List.Item>
        <List.Divider />
        <List.Item
          onClick={onRefreshClick}
          onMouseDown={handleContextMenuClick}
          style={{ color: 'black' }}
          onMouseEnter={(e) => {
            e.currentTarget.style.color = 'white';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.color = 'black';
          }}
        >
          Refresh
        </List.Item>
        <List.Divider />
        <List.Item>Paste</List.Item>
        <List.Item>Paste Shortcut</List.Item>
        <List.Item>Undo Copy</List.Item>
        <List.Divider />
        <List.Item>New</List.Item>
        <List.Divider />
        <List.Item>Properties</List.Item>
      </List>
    </div>
    // <div
    //   className='context-menu'
    //   style={{
    //     position: 'fixed',
    //     top: positionY + 'px',
    //     left: positionX + 'px',
    //   }}
    // >
    //   <Button onClick={onRefreshClick} onMouseDown={handleContextMenuClick}>
    //     Refresh page
    //   </Button>
    // </div>
  );
};

export default ContextMenu;
