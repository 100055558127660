import React from 'react';
// import DataContext from '../contexts/dataContext';
import { TaskBar, List } from '@react95/core';
import styled from 'styled-components';

const Link = styled.a`
  text-decoration: none;
  color: inherit;
`;

function Taskbar() {
  // const { projectRepo, react95Repo } = useContext(DataContext).getProjectInfo();
  // console.log(projectRepo, react95Repo);
  return (
    <TaskBar
      className='taskbar'
      list={
        <List>
          <Link href='https://google.com' target='_blank'>
            <List.Item className='pointer taskbar__list' icon='explore'>
              Explore
            </List.Item>
          </Link>

          <Link href='https://spotify.com' target='_blank'>
            <List.Item className='pointer' icon='cd_music'>
              CD Music
            </List.Item>
          </Link>

          <Link href='https://trello.com' target='_blank'>
            <List.Item className='pointer' icon='bookmark'>
              Bookmark
            </List.Item>
          </Link>

          <Link href='https://notion.so' target='_blank'>
            <List.Item className='pointer' icon='notepad'>
              Notepad
            </List.Item>
          </Link>

          <Link href='https://docs.google.com' target='_blank'>
            <List.Item className='pointer' icon='wordpad'>
              Wordpad
            </List.Item>
          </Link>

          <Link href='https://www.photopea.com/' target='_blank'>
            <List.Item className='pointer' icon='mspaint'>
              MS Paint
            </List.Item>
          </Link>

          <List.Divider />
          <Link href='https://chat.openai.com' target='_blank'>
            <List.Item className='pointer' icon='network_2'>
              Connect to SkyNet
            </List.Item>
          </Link>

          <Link href='https://media.makeameme.org/created/your-internet-has.jpg'>
            <List.Item className='pointer' icon='computer_3'>
              Shut down...
            </List.Item>
          </Link>
        </List>
      }
    />
  );
}

export default Taskbar;
