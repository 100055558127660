export default class DataService {
  _data = {
    projectRepo: 'https://github.com/habitata',
    react95Repo: 'https://github.com/React95/React95',
    items: [
      {
        id: 'about',
        name: 'About.txt',
        icon: 'info_bubble',
        content: {
          paragraphs: [
            "Greetings! I'm a fullstack developer originally from the stunning landscapes of Yakutia.",
            'I started my journey into the world of programming in early 2023, driven by a strong desire for continuous learning and personal growth.',
            'My fascination with creating digital visual experiences led me to the realm of front-end development, where I discovered my true passion.',
            'I love the art of blending design elements with cutting-edge programming techniques, resulting in user interfaces that are not only visually appealing but also highly functional.',
          ],
        },
      },
      // {
      //   id: 'resume',
      //   name: 'Resume.txt',
      //   icon: 'notepad_2',
      //   content: {
      //     resumeLink: 'https://www.youtube.com/watch?v=r38VGPDh4fw',
      //     workExperience: [
      //       {
      //         jobTitle: 'CEO',
      //         company: 'Aivers ltd',
      //         location: 'RS(Y)',
      //         period: '2019 - Present',
      //         accomplishments: ['Best', 'Company', 'Ever'],
      //       },
      //       // {
      //       //   jobTitle: 'Software Developer',
      //       //   company: 'No job',
      //       //   location: 'Yakutistan',
      //       //   period: 'FROM INFINITY - TO BEYOND',
      //       //   accomplishments: ['No accomplishments', 'At all', "I'm that bad"],
      //       // },
      //     ],
      //     education: [
      //       {
      //         credit:
      //           'Self-education, primarily focusing on front-end development',
      //         place: 'Home',
      //         gpa: '亡羊补牢，为时不晚',
      //         period: '2023 - Present',
      //       },
      //       {
      //         credit: 'Bachelor of economics',
      //         place: 'China, Beijing',
      //         gpa: 'Wasted time?',
      //         period: '2005 - 2012',
      //       },
      //     ],
      //   },
      // },
      {
        id: 'projects',
        name: 'Projects.txt',
        icon: 'flying_through_space_100',
        content: {
          projects: [
            {
              title: 'Tamam Pro',
              description: 'A service for finding tasks and professionals',
              myRole: 'Fullstack developer',
              image: '/images/tamam.png',
              techStack: ['MERN'],
              url: 'https://tamam.pro/',
            },
            {
              title: 'Guardians Spotify',
              description:
                'Listen to awesome mixes in one place. Be a guardian.',
              myRole: '',
              image: '/images/guardians.png',
              techStack: ['Next.js, Supabase, Tailwind'],
              url: 'https://aivers-spotify.vercel.app/',
            },
            {
              title: 'Mary Gibbs Dojo',
              description: 'Personal website of a pro gamer in Mordhau',
              techStack: ['React, Vite, Tailwind'],
              url: 'https://habitata.github.io/marygibbsdojo/',
            },
            {
              title: 'Sakha Education',
              description:
                'An LMS (Learning Management System) and website builder designed for educational companies. The project has been terminated by the government, replaced with an "awesome" solution available at https://obr.sakha.gov.ru.',
              myRole: '',
              video: '/videos/education.mp4',
              image: '',
              techStack: [
                'Website builder – PHP 1.6 Diamond edition',
                'LMS – Vue.js + Node.js (mdimai666)',
              ],
              url: 'https://sakha.education/',
            },
          ],
        },
      },
      {
        id: 'skills',
        name: 'Skills.txt',
        icon: 'progman_11',
        content: {
          hard: [
            {
              name: 'JavaScript, TypeScript',
              progress: 80,
            },
            {
              name: 'React, Next.js',
              progress: 80,
            },
            {
              name: 'Node.js, Express',
              progress: 60,
            },
            {
              name: 'MongoDB, PostgreSQL',
              progress: 60,
            },
            {
              name: 'C# .NET',
              progress: 20,
            },
          ],
          soft: 'Very soft',
        },
      },
      {
        id: 'contact',
        name: 'Contact.txt',
        icon: 'inetcfg_2301',
        content: {
          emailText: 'You can contact me by email: ',
          email: 'ivv.rsy@gmail.com',
          socialText:
            'Or you can reach me out through social media (in the future):',
          social: [
            {
              name: 'FaLinkedin',
              link: 'https://www.linkedin.com/',
            },
            {
              name: 'FaGithub',
              link: 'https://github.com/',
            },
            {
              name: 'FaInstagram',
              link: 'https://www.instagram.com/',
            },
            {
              name: 'FaTwitter',
              link: 'https://twitter.com/',
            },
            {
              name: 'FaMedium',
              link: 'https://medium.com/',
            },
          ],
        },
      },
    ],
  };

  _playlists = {
    items: [
      {
        id: 1,
        name: 'Movies',
        icon: '/images/movies.ico',
        videos: [
          {
            url: `${process.env.PUBLIC_URL}/videos/movies/cyborg.mp4`,
            title: 'Terminator',
            background: `${process.env.PUBLIC_URL}/images/movies/cyborg.jpg`,
          },
          {
            url: `${process.env.PUBLIC_URL}/videos/movies/robocop.mp4`,
            title: 'Robocop 1987',
            background: `${process.env.PUBLIC_URL}/images/movies/robocop.jpg`,
          },
          {
            url: `${process.env.PUBLIC_URL}/videos/movies/guyver.mp4`,
            title: 'Guyver (1991) 360',
            background: `${process.env.PUBLIC_URL}/images/movies/guyver.jpg`,
          },
        ],
      },
      {
        id: 2,
        name: 'Anime',
        icon: '/images/brush.ico',
        videos: [
          {
            url: `${process.env.PUBLIC_URL}/videos/anime/eva.mp4`,
            title: 'EOE - Breathe',
            background: '/images/anime/eva.jpg',
          },
          {
            url: `${process.env.PUBLIC_URL}/videos/anime/alita.mp4`,
            title: 'Battle angel Alita',
            background: `${process.env.PUBLIC_URL}/images/anime/alita.jpg`,
          },
          {
            url: `${process.env.PUBLIC_URL}/videos/anime/golgo13.mp4`,
            title: 'Golgo 13',
            background: `${process.env.PUBLIC_URL}/images/anime/golgo13.jpg`,
          },
        ],
      },
      {
        id: 3,
        name: 'Music videos',
        icon: '/images/clips.ico',
        videos: [
          {
            url: `${process.env.PUBLIC_URL}/videos/clips/supersonic.mp4`,
            title: 'J.J Fad - Supersonic',
            background: `${process.env.PUBLIC_URL}/images/clips/supersonic.jpg`,
          },
          {
            url: `${process.env.PUBLIC_URL}/videos/clips/barbie.mp4`,
            title: 'Aqua - Barbie girl',
            background: `${process.env.PUBLIC_URL}/images/clips/barbie.jpg`,
          },
          {
            url: `${process.env.PUBLIC_URL}/videos/clips/touchthis.mp4`,
            title: "Hammer - Can't touch this",
            background: `${process.env.PUBLIC_URL}/images/clips/hammer2.jpg`,
          },
        ],
      },
      {
        id: 4,
        name: 'Courses',
        icon: '/images/courses.ico',
        videos: [
          {
            url: `${process.env.PUBLIC_URL}/videos/courses/coding.mp4`,
            title: 'C++',
            background: `${process.env.PUBLIC_URL}/images/courses/cplus.jpg`,
          },
          {
            url: `${process.env.PUBLIC_URL}/videos/courses/html.mp4`,
            title: 'HTML Lesson',
            background: `${process.env.PUBLIC_URL}/images/courses/html.jpg`,
          },
          {
            url: `${process.env.PUBLIC_URL}/videos/courses/ipv.mp4`,
            title: 'Internal Pointer Variable',
            background: `${process.env.PUBLIC_URL}/images/courses/ipv.jpg`,
          },
        ],
      },
    ],
  };

  getItems() {
    return this._data.items.map(({ id, name, icon }) => ({
      id,
      name,
      icon,
    }));
  }

  getItem(id) {
    return this._data.items.find((x) => x.id === id);
  }

  getProjectInfo() {
    return {
      projectRepo: this._data.projectRepo,
      react95Repo: this._data.react95Repo,
    };
  }

  getPlaylists() {
    return this._playlists.items.map(({ id, name, icon, videos }) => ({
      id,
      name,
      icon,
      videos,
    }));
  }

  getPlaylist(id) {
    return this._playlists.items.find((x) => x.id === id);
  }
}
