import React from 'react';
import { playerContextFilter } from '@cassette/core';
import { Frame } from '@react95/core';

let PlaylistMenu = ({
  playlist,
  activeTrackIndex,
  onSelectTrackIndex,
  isMobile,
  // closePlaylist,
}) => {
  return (
    <div>
      <Frame padding={6}>
        <Frame
          bg='white'
          boxShadow='in'
          height='100%'
          style={{
            overflowY: 'auto',
            maxHeight: '20vh',
          }}
        >
          <div
            style={{
              padding: isMobile ? '0 10px' : '0 20px',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {playlist.map((track, i) => {
              const playing = activeTrackIndex === i;
              if (playing && track.background) {
                document.body.style.backgroundImage = `url('${track.background}')`;
              }
              return (
                <div
                  key={track.title}
                  style={{
                    flex: '0 0 50%',
                    padding: '4px',
                    color: playing ? 'blue' : 'black',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.color = 'blue';
                    // e.currentTarget.style.background = '#010e7b';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = playing ? 'blue' : 'black';
                    // e.currentTarget.style.background = '#c1c5c9';
                  }}
                >
                  {playing && (
                    <div style={{ cursor: 'pointer' }}>
                      {i + 1}* {track.title}
                    </div>
                  )}
                  {!playing && (
                    <div onClick={() => onSelectTrackIndex(i)}>
                      {i + 1}. {track.title}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Frame>
      </Frame>
    </div>
  );
};

PlaylistMenu = playerContextFilter(PlaylistMenu, [
  'playlist',
  'activeTrackIndex',
  'onSelectTrackIndex',
]);

export default PlaylistMenu;
