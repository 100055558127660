import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button } from '@react95/core';

const BsodScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/images/bsod.jpg');
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-color: #020bac;
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: black;
  background-position: center;
  z-index: 9999;
`;

const LoadingGif = styled.img`
  width: auto;
  height: 100%;
  position: center;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Bsod = ({ visible, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (visible && isLoading) {
      audioRef.current.play();
      const loadingTimer = setTimeout(() => {
        setIsLoading(false);
        onClose();
      }, 6000);

      return () => {
        clearTimeout(loadingTimer);
      };
    }
  }, [visible, isLoading, onClose]);

  const handleReload = () => {
    setIsLoading(true);
  };

  return visible ? (
    <BsodScreen>
      {isLoading ? (
        <LoadingContainer>
          <LoadingGif src='/images/loading.gif' alt='Windows Loading' />
          <audio ref={audioRef} src='/music/win95loading.mp3' />{' '}
          {/* Элемент аудио */}
        </LoadingContainer>
      ) : (
        <CloseButton onClick={handleReload}>Reboot Win95</CloseButton>
      )}
    </BsodScreen>
  ) : null;
};

export default Bsod;
