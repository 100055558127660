import React, { useState, useContext, useEffect } from 'react';
import Explorer from './Explorer';
import Notepad from './Notepad';
import DataContext from '../contexts/dataContext';
import Shortcuts from './Shortcuts';
import Player from './Player';
import VideoPlayer from './VideoPlayer';
import MyComp from './MyComp';
import Bsod from './Bsod';
import { disposeWebamp } from '../utils/startWebamp';

function Desktop() {
  const isMobile = window.innerWidth < 850;

  const data = useContext(DataContext);
  const [showBSOD, setShowBSOD] = useState(false);

  const [explorerOpened, toggleExplorer] = useState(false);
  const [notepadOpened, toggleNotepad] = useState(false);
  const [videoOpened, toggleVideo] = useState(false);
  const [myCompOpened, toggleMyComp] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 67) {
        setShowBSOD(true);
        const video = document.querySelector('.player video');
        video && video.pause();
        toggleExplorer(false);
        toggleVideo(false);
        toggleNotepad(false);
        toggleMyComp(false);
        disposeWebamp();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const files = data.getItems();
    setItems(files);
    toggleExplorer(false);
    toggleVideo(false);
    setSelectedItem(files[0]);
    toggleNotepad(false);
  }, [data]);

  const closeExplorer = () => {
    toggleExplorer(false);
    toggleNotepad(false);
  };

  const openExlorer = () => {
    toggleExplorer(true);
  };

  const closeNotepad = () => {
    toggleNotepad(false);
  };

  const openNotepad = (item) => {
    setSelectedItem(item);
    toggleNotepad(true);
  };

  const openVideo = () => {
    toggleVideo(true);
  };

  const closeVideoPlayer = () => {
    const video = document.querySelector('.player video');
    video && video.pause();
    toggleVideo(false);
  };

  const openMyComp = () => {
    toggleMyComp(true);
  };

  const closeMyComp = () => {
    toggleMyComp(false);
  };

  const handleCloseBsod = () => {
    setShowBSOD(false);
  };

  return (
    <React.Fragment>
      <Bsod visible={showBSOD} onClose={handleCloseBsod} />

      <Shortcuts
        openExplorer={openExlorer}
        openVideo={openVideo}
        openMyComp={openMyComp}
      />
      {myCompOpened ? <MyComp closeMyComp={closeMyComp} /> : null}
      {explorerOpened && (
        <Explorer
          items={items}
          closeExplorer={closeExplorer}
          openNotepad={openNotepad}
          isMobile={isMobile}
        />
      )}
      {notepadOpened && (
        <Notepad
          closeNotepad={closeNotepad}
          selectedItem={selectedItem}
          isMobile={isMobile}
        />
      )}

      {videoOpened ? (
        <div className=''>
          <VideoPlayer isMobile={isMobile} closeVideo={closeVideoPlayer} />
        </div>
      ) : (
        <div style={{ display: 'none' }}>
          <VideoPlayer isMobile={isMobile} closeVideo={closeVideoPlayer} />
        </div>
      )}
      <Player />
    </React.Fragment>
  );
}

export default Desktop;
